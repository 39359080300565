<template>
  <div class="flex flex-row items-center whitespace-nowrap flex-nowrap">
    <template v-if="isStyled">
      {{ prev.join(' ') }}
      <div
        class="relative font-bold font-caveat mr-[5px]"
        :style="{
          'font-size': fontSizePx + 'px',
        }"
      >
        <div class="absolute z-0 h-full w-[120%] -left-[10%] -bottom-[25%]">
          <svg
            id="styledMy"
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="17.814"
            viewBox="0 0 49 17.814"
            class="w-full h-full mx-auto"
            :style="{
              fill: getBgColor(props.styleType),
              stroke: '#FFFFFF00',
            }"
          >
            <path
              id="Pfad_8122"
              data-name="Pfad 8122"
              d="M-12888.954-17685.609c-5.77.77-9.746,2.176-18.982,3.715s-15.776,1.469-15.9,3.262,1.411,2.883,1.026,4.418-2.373.641-2.309,2.049,2.052,4.738,11.415,3.457,20.33-4.418,26.294-4.865,9.108.514,9.493-.641-1.409-3.2-.9-4.867,3.593-5.633,2.438-6.527S-12883.181-17686.379-12888.954-17685.609Z"
              transform="translate(12925.125 17686.236)"
            />
          </svg>
        </div>
        <span class="relative z-1">{{ styled }}</span>
      </div>
      {{ next.join(' ') }}
    </template>
    <template v-else>{{ text }}</template>
  </div>
</template>

<script setup lang="ts">
import {
  useStyledMy,
  EStyleType,
} from '@/components/layout/header/components/styledMy/useStyledMy';
import { getBgColor } from '@/components/layout/header/components/styledMy/vdv/utils';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  fontSizePx: {
    type: Number,
    required: false,
    default: 18,
  },
  styleType: {
    type: Number as PropType<EStyleType>,
    required: false,
    default: () => EStyleType.default,
  },
});

const { isStyled, styled, prev, next } = useStyledMy(props.text);
</script>
